import httpCommon from '@/plugins/http-common'

function parseJwt (token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

export default {
  state: {
    fullname: null,
    admin: false,
    advertiser: false,
    token: null,
  },
  getters: {
    isAuthenticated: function (state) {
      return !!state.token
    },
    isAdmin: function (state) {
      return !!state.admin
    },
    isAdvertiser: function (state) {
      return !!state.advertiser
    },
    getFullName: function (state) {
      return state.fullname
    },
    StateToken: (state) => state.token,
  },
  mutations: {
    setToken: function (state, data) {
      console.dir(data.data)
      localStorage.setItem('token', data.token)
      state.admin = data.data.admin
      state.advertiser = data.data.advertiser
      state.fullname = data.data.name
      state.token = data.token
    },
    logout (state, token) {
      localStorage.removeItem('token')
      state.token = token
    },
  },
  actions: {
    async login ({ commit }, user) {
      const res = await httpCommon.post('auth/login', user)
      if (res) {
        if (res.data.error) return false
        const data = parseJwt(res.data.access_token)
        await commit('setToken', {
          token: res.data.access_token,
          data: data,
        })

        return true
      }
    },

    async logout ({ commit }) {
      const token = null
      commit('logout', token)
    },
  },
}
